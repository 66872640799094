import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import { useIntersection, useMeasure } from 'react-use';

import Logger from '../../shared/logger';

import { useAuth } from '../../contexts/auth';
import { useMonitor } from '../../contexts/monitor';
import { useRoomsOnlineContext } from '../../contexts/roomsOnlineList';

import {
  HOME_GRID_MODES,
  SUPERADMIN_LEVEL,
  PRICES_RANGES_FILTER,
} from '../../shared/constants';
import { setHomeGridMode } from '../../shared/cookies';

import ListPerformersOnline from '../../components/Home/ListPerformersOnline';
import UsersCounter from '../../components/Home/UsersCounter';
import Card from '../../components/Card';
import Input from '../../components/atoms/Input';
import Radio from '../../components/atoms/Radio';
import Select from '../../components/atoms/Select';
import SelectorStudio from '../../components/Selectors/SelectorStudio';
import SelectorManager from '../../components/Selectors/SelectorManager';
import SelectorRoomType from '../../components/Selectors/SelectorRoomType';
import Monitor from '../../components/Monitor/Monitor';
import CloseAllRooms from '../../components/Home/CloseAllRooms';
import Tooltip from '../../components/Tooltip';

import IconLens from '../../public/assets/images/icons/lens.svg';
import IconOnline from '../../public/assets/images/icons/monitor.svg';
import IconGridView from '../../public/assets/images/icons/grid.svg';
import IconListView from '../../public/assets/images/icons/list_view.svg';

const logger = new Logger('HomePage');

const PerformersOnlineList = () => {
  const { formatMessage: t } = useIntl();
  const { level, idStudio: userStudio } = useAuth();
  const { setMonitorFloating } = useMonitor();
  const intersectionRef = useRef(null);

  const [listPerformersRef, { height: listHeight }] = useMeasure();
  const intersection = useIntersection(intersectionRef, {});

  const {
    listPerformersOnline,
    studioFiltered,
    roomModeFiltered,
    roomTypeFiltered,
    deviceFiltered,
    originalListPerformersOnline,
    sortMode,
    currentViewMode,
    loadingPerformersOnline,
    lastUpdate,
    isFiltered,
    resetFilters,
    setCurrentViewMode,
    setStudioFiltered,
    setManagerFiltered,
    setRoomModeFiltered,
    setTagFiltered,
    setDeviceFiltered,
    setRoomTypeFiltered,
    pricesRangeSelected,
    setPricesRangeSelected,
    setSortMode,
  } = useRoomsOnlineContext();

  useEffect(() => {
    setHomeGridMode(currentViewMode);
  }, [currentViewMode]);

  useEffect(() => {
    //checks if the monitor should float (based on the vertical scroll position) and if it has enough room to float.
    //if the height of the rooms list is greater than 580px then it can float
    if (
      intersection &&
      intersection.intersectionRatio < 1 &&
      listHeight > 580
    ) {
      setMonitorFloating(true);
    } else {
      setMonitorFloating(false);
    }

    return () => {
      setMonitorFloating(true);
    };
  }, [intersection, listHeight]);

  return (
    <Card
      Icon={IconOnline}
      title={
        <div className="flex justify-between items-center">
          <span>
            {loadingPerformersOnline && 'Obteniendo modelos online...'}
            {!loadingPerformersOnline && (
              <span>
                {originalListPerformersOnline &&
                  `${originalListPerformersOnline.length} modelos online.`}{' '}
                <small>Última actualización {lastUpdate}</small>
              </span>
            )}
          </span>
          <span className="flex gap-2">
            <Radio
              label="Ocupadas primero"
              checked={sortMode === 'busy'}
              name="sort"
              onChange={() => setSortMode('busy')}
            />
            <Radio
              label="Orden alfabético ASC"
              checked={sortMode === 'nick'}
              name="sort"
              onChange={() => setSortMode('nick')}
            />
            <Radio
              label="Nuevas primero"
              checked={sortMode === 'new'}
              name="sort"
              onChange={() => setSortMode('new')}
            />
          </span>
        </div>
      }
    >
      <div className="flex gap-1">
        <Input.Text
          placeholder="Buscar"
          Icon={IconLens}
          type="search"
          onChange={(e) => setTagFiltered(e.target.value)}
          disabled={loadingPerformersOnline}
          fullWidth={false}
        />
        <SelectorStudio
          label={false}
          value={studioFiltered}
          onChange={(studioId) => setStudioFiltered(parseInt(studioId))}
          disabled={loadingPerformersOnline}
        />
        <SelectorManager
          label={false}
          onChange={(managerId) => setManagerFiltered(parseInt(managerId))}
          studioId={studioFiltered}
          disabled={loadingPerformersOnline}
        />
        <Select
          label={false}
          value={roomModeFiltered}
          onChange={(e) => setRoomModeFiltered(e.target.value)}
          disabled={loadingPerformersOnline}
        >
          <option value={-1}>{t({ id: 'any_room_mode' })}</option>
          <option value="freechat">Free</option>
          <option value="private">{t({ id: 'room_mode_private' })}</option>
          <option value="paused">Paused</option>
          <option value="vip">VIP</option>
        </Select>
        <Select
          label={false}
          value={deviceFiltered}
          onChange={(e) => setDeviceFiltered(e.target.value)}
          disabled={loadingPerformersOnline}
        >
          <option value={-1}>{t({ id: 'any_room_device' })}</option>
          <option value="1">{t({ id: 'mobile_phone' })}</option>
          <option value="0">Desktop</option>
        </Select>
        <SelectorRoomType
          label={false}
          value={roomTypeFiltered}
          onChange={(roomType) => setRoomTypeFiltered(parseInt(roomType))}
          disabled={loadingPerformersOnline}
        />

        <Select
          label={false}
          value={pricesRangeSelected}
          onChange={(e) => setPricesRangeSelected(e.target.value)}
          disabled={loadingPerformersOnline}
        >
          <option value={-1}>{t({ id: 'any_group_price' })}</option>
          {PRICES_RANGES_FILTER.map((range) => {
            return (
              <option key={range.value} value={range.value}>
                {range.label} {t({ id: 'credits' })}
              </option>
            );
          })}
        </Select>
        {level === SUPERADMIN_LEVEL && isFiltered() && (
          <button
            className="border border-teal-500 bg-teal-600 p-2 text-xs text-white font-bold"
            onClick={(e) => {
              e.preventDefault();
              resetFilters();
            }}
          >
            Reset
          </button>
        )}
      </div>

      <div className="h-2" />

      <div className="flex justify-between border-y items-center py-1">
        <UsersCounter />
        <div className="flex gap-1 items-center ">
          <button onClick={() => setCurrentViewMode(HOME_GRID_MODES.GRID)}>
            <Tooltip content={t({ id: 'grid_view' })}>
              <IconGridView
                className={twMerge(
                  'w-7 h-7 text-gray-400 hover:text-gray-600',
                  currentViewMode === 'grid' && 'text-gray-600'
                )}
              />
            </Tooltip>
          </button>
          <button onClick={() => setCurrentViewMode(HOME_GRID_MODES.LIST)}>
            <Tooltip content={t({ id: 'list_view' })}>
              <IconListView
                className={twMerge(
                  'w-7 h-7 text-gray-400 hover:text-gray-600',
                  currentViewMode === 'list' && 'text-gray-600'
                )}
              />
            </Tooltip>
          </button>
          <CloseAllRooms listRooms={originalListPerformersOnline} />
        </div>
      </div>

      <div className="h-2" />

      {loadingPerformersOnline && listPerformersOnline.length === 0 && (
        <div className="w-full text-black p-2 flex items-center justify-center">
          Cargando salas online...
        </div>
      )}

      {!loadingPerformersOnline && listPerformersOnline.length === 0 && (
        <div className="w-full bg-yellow-100 text-black p-2 flex items-center justify-center">
          No hay salas online
        </div>
      )}

      <div ref={intersectionRef} className="h-0" />
      <Monitor />

      <div ref={listPerformersRef}>
        <ListPerformersOnline />
      </div>
    </Card>
  );
};

export default PerformersOnlineList;
